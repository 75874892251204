import { FunctionComponent, useEffect, useState } from "react";
import logo from "../samnytt_logo.webp";
import HeaderDropdown from "./HeaderDropdown";
import { useFirebase } from "../lib/firebaseContext";
import HeaderBackButton from "./HeaderBackButton";

interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
  const { logOut, userData } = useFirebase();

  return (
    <nav
      className="flex items-center justify-between h-16 px-4 py-2 sm:pl-12 sm:pr-24 mb-5 
    bg-white shadow-[0px_2px_8px_2px_#ededed] select-none"
    >
      <HeaderBackButton />

      <img
        className="shrink-0 w-min-fit p-1.5 sm:p-0 h-full cursor-pointer"
        src={logo}
        alt="samnytt logo"
        // onClick={() => alert("TODO: go to main route")}
      ></img>
      <span className="flex justify-center items-center sm:ml-auto">
        {userData && (
          <p className="hidden sm:inline text-base font-medium mr-2">
            {userData.name} {userData.surname}
          </p>
        )}
        <HeaderDropdown letter={userData?.name} onLogout={logOut} />
      </span>
    </nav>
  );
};

export default Header;
