import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  User,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { app, auth, db } from "./firebase";
import {
  DocumentData,
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserData } from "../types/UserData";

interface FirebaseContextProps {
  user: User | null;
  userData: UserData;
  userSubscriptions: DocumentData[] | null;
  userInvoices: any;
  loading: boolean;
  signUp: (password: string, data: UserData) => Promise<void> | any;
  signIn: (email: string, password: string) => Promise<void> | any;
  logOut: () => Promise<void>;
  createUserCheckout: (
    subscription: "plus" | "plus-annonsfritt"
  ) => Promise<void>;
  fetchUserSubscriptions: (uid: string) => Promise<DocumentData[]>;
  createUserPortal: () => Promise<void>;
  isUserEmailExist: (email: string) => Promise<boolean>;
  updateUserDocument: (uid: string, userData: UserData) => Promise<void>;
}

const FirebaseContext = createContext<FirebaseContextProps | undefined>(
  undefined
);

const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return context;
};

interface FirebaseProviderProps {
  children: ReactNode;
}

const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<any>(null); //TODO Add UserData time | null
  // const [userSubscriptions, setUserSubscriptions] = useState<
  //   DocumentData[] | null
  // >(null);
  const [userSubscriptions, setUserSubscriptions] = useState<[] | null>(null);
  const [userInvoices, setUserInvoices] = useState<DocumentData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("Auth has changed");
      setUser(user);

      if (user) {
        // Load & set user document
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setUserData(userDocSnapshot.data());
        }
        // Load & set subscriptions
        const subs = await fetchUserSubscriptions(user.uid);
        if (subs) {
          setUserSubscriptions(subs);
        }
      } else {
        setUserData(null);
        setUserSubscriptions(null);
        setUserInvoices(null);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const createUserCheckout = async (
    subscription: "plus" | "plus-annonsfritt"
  ) => {
    const checkoutSessionsCollection = collection(
      doc(collection(db, "users"), user?.uid),
      "checkout_sessions"
    );
    const docRef = await addDoc(checkoutSessionsCollection, {
      price:
        subscription === "plus"
          ? "price_1MtSdEIFfKeJDUxHVearg0ee"
          : "price_1Mya9yIFfKeJDUxHrMvRwP1p",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    const unsub = onSnapshot(docRef, (snap) => {
      // @ts-ignore
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });

    // Remember to unsubscribe when you no longer need the listener
    // Example: unsub();
  };

  const createUserPortal = async () => {
    const functionsInstance = getFunctions(app, "europe-west2");
    const createPortalLink = httpsCallable(
      functionsInstance,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    try {
      const { data }: any = await createPortalLink({
        returnUrl: /* returnUrl || */ window.location.origin,
        /* locale: locale, */
        /*         configuration:   configuration || "bpc_1JSEAKHYgolSBA358VNoc2Hs",
         */
      });
      window.location.assign(data.url);
    } catch (error: any) {
      console.error(`An error occurred: ${error.message}`);
    }
  };

  const fetchUserSubscriptions = async (uid: string) => {
    const userDocRef = collection(db, "users");
    const userSubscriptionsCollection = collection(
      userDocRef,
      uid,
      "subscriptions"
    );

    const q = query(userSubscriptionsCollection);
    const querySnapshot = await getDocs(q);

    const subscriptions: any = [];
    const subscriptionsIds: string[] = [];
    let invoices: any = [];

    querySnapshot.forEach((subDoc) => {
      subscriptions.push({ ...subDoc.data(), id: subDoc.id });
      subscriptionsIds.push(subDoc.id);
    });

    for (const id of subscriptionsIds) {
      const invoicesRef = collection(
        userDocRef,
        uid,
        "subscriptions",
        id,
        "invoices"
      );
      const q = query(invoicesRef);
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((invoiceDoc) => {
        invoices.push(invoiceDoc.data());
      });
    }

    setUserInvoices(invoices);
    return subscriptions;
  };

  const createUserDocument = async (user: User, userData: UserData) => {
    console.log("Creating DB");

    const userRef = doc(db, "users", user.uid);
    const data = {
      // email: user.email,
      createdAt: new Date(),
      uid: user.uid,
      ...userData,
    };

    await setDoc(userRef, data);
  };

  const updateUserDocument = async (uid: string, userData: UserData) => {
    console.log("Updating user document");

    const userDocRef = doc(db, "users", uid);
    await updateDoc(userDocRef, userData);

    const userDoc = await getDoc(userDocRef);
    setUserData(userDoc.data());
  };

  const signUp = async (password: string, data: UserData) => {
    try {
      const userCred = await createUserWithEmailAndPassword(
        auth,
        data.email,
        password
      );
      if (userCred.user) {
        await createUserDocument(userCred.user, data);
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
  };

  const isUserEmailExist = async (email: string) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        userData,
        userSubscriptions,
        userInvoices,
        loading,
        signUp,
        signIn,
        logOut,
        createUserCheckout,
        fetchUserSubscriptions,
        createUserPortal,
        isUserEmailExist,
        updateUserDocument,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { useFirebase, FirebaseProvider };
