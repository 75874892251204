import { Dispatch, FunctionComponent, useEffect, useState } from "react";
import PasswordInput from "./input/PasswordInput";
import ErrorMessage from "./ErrorMessage";
import TextInput from "./input/TextInput";
import { UserData } from "../types/UserData";
import useKey from "../hooks/useKey";

interface SignUpFlowProps {
  email: string;
  password: string;
  passwordRepeated: string;
  setPassword: Dispatch<React.SetStateAction<string>>;
  setPasswordRepeated: Dispatch<React.SetStateAction<string>>;
  backToEmail: () => void;
  signUpUser: (arg0: UserData) => Promise<void>;
}

const SignUpFlow: FunctionComponent<SignUpFlowProps> = ({
  email,
  password,
  setPassword,
  passwordRepeated,
  setPasswordRepeated,
  signUpUser,
  backToEmail,
}) => {
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [userFields, setUserFields] = useState<UserData>({
    name: "",
    surname: "",
    email: "",
    address: "",
    city: "",
    zipcode: "",
    telephone: "",
  });

  const userFieldsHandler = (fieldName: string, val: string) => {
    setUserFields({ ...userFields, [fieldName]: val });
  };

  useEffect(() => {
    userFieldsHandler("email", email);
  }, [email]);

  const signUp = () => {
    if (password.length >= 6 && password === passwordRepeated) {
      signUpUser(userFields);
    } else {
      setPasswordError(true);
    }
  };

  useKey("Enter", signUp, [password, passwordRepeated, userFields]);

  return (
    <>
      <h1 className="samnytt-card-headline">Dina uppgifter</h1>
      <div className="flex">
        <TextInput
          className="mr-6"
          label="Förnamn"
          fieldName="name"
          value={userFields.name}
          onChange={userFieldsHandler}
          autoFocus={true}
        />
        <TextInput
          label="Efternamn"
          fieldName="surname"
          value={userFields.surname}
          onChange={userFieldsHandler}
        />
      </div>
      <TextInput
        label="E-postadress"
        placeholder="Gatunamn och nummer"
        fieldName="email"
        value={userFields.email}
        onChange={userFieldsHandler}
        disabled={true}
      />
      <TextInput
        label="Gatuadress"
        fieldName="address"
        value={userFields.address}
        onChange={userFieldsHandler}
      />
      <div className="flex">
        <TextInput
          className="mr-6"
          label="Ort"
          fieldName="city"
          value={userFields.city}
          onChange={userFieldsHandler}
        />
        <TextInput
          label="Postnummer"
          fieldName="zipcode"
          value={userFields.zipcode}
          onChange={userFieldsHandler}
        />
      </div>
      <TextInput
        className="mb-20"
        label="Telefon"
        fieldName="telephone"
        value={userFields.telephone}
        onChange={userFieldsHandler}
      />
      <PasswordInput
        className="mb-4"
        value={password}
        label="Skapa kontolösenord"
        onChange={setPassword}
        placeholder="Lösenord"
      />
      <PasswordInput
        value={passwordRepeated}
        label="Repetera lösenord"
        onChange={setPasswordRepeated}
        placeholder="Lösenord"
      />

      {passwordError && <ErrorMessage text="Lösenorden matchar inte." />}

      <button className="mt-6 samnytt-primary-btn" onClick={signUp}>
        Registrera konto
      </button>
      <button
        className="h-11 w-full mt-3 py-3 px-4 rounded text-gray-700 hover:text-gray-800 bg-transparent hover:bg-gray-100 transition-all text-sm select-none outline-none"
        onClick={backToEmail}
      >
        Tillbaka
      </button>
    </>
  );
};

export default SignUpFlow;
