import { Dispatch, FunctionComponent, useEffect, useState } from "react";
import ErrorMessage from "./ErrorMessage";
import useKey from "../hooks/useKey";

interface EmailFlowProps {
  email: string;
  setEmail: Dispatch<React.SetStateAction<string>>;
  continueWithEmail: () => Promise<void>;
}

const EmailFlow: FunctionComponent<EmailFlowProps> = ({
  email,
  setEmail,
  continueWithEmail,
}) => {
  const [showError, setShowError] = useState<boolean>(false);

  const submitEmail = () => {
    const validateEmail = (email: string): boolean =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (validateEmail(email)) {
      continueWithEmail();
    } else {
      setShowError(true);
    }
  };

  useKey("Enter", submitEmail, [email]);

  return (
    <>
      <h1 className="samnytt-card-headline">
        Logga in eller skapa ett nytt konto
      </h1>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        tabIndex={1}
        type="email"
        placeholder="Ange din e-postadress*"
        className=" py-3 text-sm samnytt-input"
      ></input>

      {showError && <ErrorMessage text="Fyll i en korrekt e-postadress." />}

      <button className="mt-6 samnytt-primary-btn" onClick={submitEmail}>
        Fortsätt
      </button>
    </>
  );
};

export default EmailFlow;
