import { Dispatch, FunctionComponent, useRef, useState } from "react";

interface PasswordInputProps {
  value: string;
  label: string;
  onChange: Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  className?: string;
  autoFocus?: boolean;
}

const PasswordInput: FunctionComponent<PasswordInputProps> = ({
  value,
  label,
  onChange,
  placeholder,
  className = "",
  autoFocus = false,
}) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const togglePasswordVisible = () => {
    setPasswordVisible((val) => !val);
    inputRef.current?.focus();
  };

  return (
    <div className={`relative w-full shrink-1 ${className}`}>
      <label className="samnytt-input-label" htmlFor={label}>
        {label}:
      </label>
      <div className="h-11 relative">
        <input
          ref={inputRef}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="py-3 pr-10 block samnytt-input"
          type={passwordVisible ? "text" : "password"}
          placeholder={placeholder}
          id={label}
          autoFocus={autoFocus}
        ></input>
        <button
          className="samnytt-svg-wrapper-for-btn"
          onClick={togglePasswordVisible}
        >
          <svg
            className="samnytt-svg-icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            {passwordVisible ? (
              <path d="M637.846 619.846 589 571q8-58.385-32.192-95.192Q516.615 439 465 447l-48.846-48.846q8.077-4.154 27.038-8.231 18.962-4.077 36.808-4.077 72.154 0 121.154 49t49 121.154q0 17.846-3.077 34.577-3.077 16.731-9.231 29.269Zm129.462 127.923L719 701q38-29 69-64.5t52-80.5q-51-103-146.5-163.5T480 332q-29 0-58.5 4T366 348l-52.231-52.231q37.846-15.154 80.423-22.462Q436.769 266 480 266q144.231 0 261.731 80.077T912.923 556q-22.461 55.231-59.346 103.654-36.884 48.423-86.269 88.115Zm29.615 237.77L629.231 818.308q-26.539 11.769-64.885 19.731Q526 846 480 846q-145 0-262.731-80.077Q99.539 685.846 47.077 556q22.307-54 57.961-100.769 35.654-46.769 78.962-81.154l-113.539-115 46.308-46.308 726.462 726.462-46.308 46.308ZM232.307 421.385q-31.384 24.461-62.807 59.307Q138.077 515.539 120 556q51 103 146.5 163.5T480 780q26.615 0 55.269-4.731 28.654-4.73 38.346-10.577L528.385 717q-6.462 4.308-21.385 6.731-14.923 2.423-27 2.423-72.154 0-121.154-49t-49-121.154q0-11.308 2.423-25.346 2.423-14.039 6.731-23.039l-86.693-86.23ZM543 524Zm-139.539 68.539Z" />
            ) : (
              <path d="M480.181 726.154q70.973 0 120.473-49.681t49.5-120.654q0-70.973-49.681-120.473t-120.654-49.5q-70.973 0-120.473 49.681t-49.5 120.654q0 70.973 49.681 120.473t120.654 49.5ZM479.882 666Q434 666 402 633.882q-32-32.117-32-78Q370 510 402.118 478q32.117-32 78-32Q526 446 558 478.118q32 32.117 32 78Q590 602 557.882 634q-32.117 32-78 32Zm.228 180q-143.956 0-260.879-79.231Q102.307 687.539 47.077 556q55.23-131.539 172.044-210.769Q335.934 266 479.89 266q143.956 0 260.879 79.231Q857.693 424.461 912.923 556q-55.23 131.539-172.044 210.769Q624.066 846 480.11 846ZM480 556Zm0 224q115 0 211.872-60.582Q788.745 658.836 840 556q-51.255-102.836-148.128-163.418Q595 332 480 332q-115 0-211.872 60.582Q171.255 453.164 120 556q51.255 102.836 148.128 163.418Q365 780 480 780Z" />
            )}
          </svg>
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;
