import { FunctionComponent } from "react";
import PlanCard from "../components/PlanCard";
import { useNavigate } from "react-router-dom";

interface CheckoutProps {}

const Checkout: FunctionComponent<CheckoutProps> = () => {
  const navigate = useNavigate();

  return (
    <div className="sm:m-2 w-full sm:flex justify-center items-center">
      <PlanCard
        className="grow-0 mx-auto rounded-r-none sm:m-0 max-w-sm"
        classNameActionBtn="samnytt-secondary-btn"
        name="Samnytt PLUS"
        price="69 kr"
        benefits={[
          "Exklusiva artiklar",
          "Exklusiva videos",
          "Exklusiva op-eds",
          "Rabatter och erbjudanden",
        ]}
        onCheckout={() =>
          navigate("/plan-options", { state: { subscription: "plus" } })
        }
      />

      <PlanCard
        className="sm:shadow-2xl mx-auto sm:m-0 sm:py-12 max-w-sm"
        name="Samnytt PLUS Annonsfritt"
        price="99 kr"
        benefits={[
          "En annonsfri upplevelse",
          "Exklusiva artiklar",
          "Exklusiva videos",
          "Exklusiva op-eds",
          "Rabatter och erbjudanden",
        ]}
        onCheckout={() =>
          navigate("/plan-options", {
            state: { subscription: "plus-annonsfritt" },
          })
        }
      />
    </div>
  );
};

export default Checkout;
