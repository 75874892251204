import { FunctionComponent, Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
interface HeaderDropdownProps {
  onLogout: () => void;
  letter: string;
}

const HeaderDropdown: FunctionComponent<HeaderDropdownProps> = ({
  onLogout,
  letter = "",
}) => {
  const [IconLetter, setIconLetter] = useState(letter);

  useEffect(() => {
    if (letter[0]) setIconLetter(letter[0]);
  }, [letter]);

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="shrink-0 bg-[#64bfb2] text-white text-xl font-semibold h-10 w-10 rounded-full hover:bg-[#4BB4A6] active:bg-[#39af9f] transition-all">
            {IconLetter}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="p-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onLogout}
                    className={`${
                      active ? "bg-[#64bfb2] text-white" : "text-gray-800"
                    } group flex h-10 w-full items-center rounded-md px-2 py-2 text-md
                    font-medium transition`}
                  >
                    <svg
                      className={`${
                        active ? "fill-white" : "fill-gray-600"
                      } mr-1.5 transition`}
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 96 960 960"
                      width="24"
                    >
                      <path d="M200 936q-33 0-56.5-23.5T120 856V296q0-33 23.5-56.5T200 216h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585 434l55-58 200 200-200 200Z" />
                    </svg>
                    Logga ut
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default HeaderDropdown;
