import { FunctionComponent } from "react";

interface ContactSupportProps {
  className?: string;
}

const ContactSupport: FunctionComponent<ContactSupportProps> = ({
  className = "",
}) => {
  return (
    <div className={`shrink-0 pb-5 samnytt-card ${className}`}>
      <p className="mb-3.5 shrink-0 samnytt-card-headline">Contact Support</p>
      <a
        className="block w-fit px-4 samnytt-secondary-btn"
        href="mailto: plus@samnytt.se"
      >
        plus@samnytt.se
      </a>
    </div>
  );
};

export default ContactSupport;
