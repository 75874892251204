import { FunctionComponent, useState } from "react";
import PrimeryButton from "./PrimaryButton";
import { PlanOptions } from "../types/PlanOptions";
import RadioOption from "./RadioOption";
import { useLocation } from "react-router-dom";

interface PlanDurationOptionsProps {
  onCheckout: Function;
}

const PlanDurationOptions: FunctionComponent<PlanDurationOptionsProps> = ({
  onCheckout,
}) => {
  const [activeOption, setActiveOption] = useState<PlanOptions>("monthly");

  const { state } = useLocation();
  const { subscription } = state;

  return (
    <div className="samnytt-card">
      <p className="samnytt-card-headline">Välj ett av nedan alternativ</p>

      <RadioOption
        className="mb-4"
        id="monthly"
        discription={
          subscription === "plus"
            ? "Månadsvis (69 kr/månaden)"
            : "Månadsvis (99 kr/månaden)"
        }
        onClick={setActiveOption}
        active={activeOption}
      />
      <RadioOption
        className="mb-4"
        id="6months"
        discription={
          subscription === "plus"
            ? "6 månader (62 kr/månaden, -10%)"
            : "6 månader (89 kr/månaden, -10%)"
        }
        onClick={setActiveOption}
        active={activeOption}
      />
      <RadioOption
        className="mb-6"
        id="12months"
        discription={
          subscription === "plus"
            ? "12 månader (57 kr/månaden, -17%)"
            : "12 månader (82 kr/månaden, -17%)"
        }
        onClick={setActiveOption}
        active={activeOption}
      />

      <PrimeryButton
        name={`Köp PLUS ${
          subscription === "plus-annonsfritt" && "Annonsfritt"
        }  ${activeOption}`}
        onClick={() => onCheckout(subscription)}
      />
    </div>
  );
};

export default PlanDurationOptions;
