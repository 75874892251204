import { FunctionComponent, useState } from "react";

import { useFirebase } from "../lib/firebaseContext";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import EmailFlow from "../components/EmailFlow";
import SignUpFlow from "../components/SignUpFlow";
import SignInFlow from "../components/SignInFlow";
import { UserData } from "../types/UserData";
import RedirectFlow from "../components/RedirectFlow";

interface SignInProps {}

const SignIn: FunctionComponent<SignInProps> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordRepeated, setPasswordRepeated] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [isUserProvidedEmail, setIsUserProvidedEmail] =
    useState<boolean>(false);
  const [isEmailUsed, setIsEmailUsed] = useState<boolean>();

  const { signUp, signIn, logOut, isUserEmailExist, userData, user, loading } =
    useFirebase();

  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [cookies, setCookie] = useCookies(["sid"]);

  const continueWithEmail = async () => {
    const response = await isUserEmailExist(email);
    setIsUserProvidedEmail(true);
    setIsEmailUsed(response);
  };

  const setCookieForRedirect = () => {
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    //?? toUTCString();

    setCookie("sid", window.btoa(JSON.stringify({ uid: user?.uid })), {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      sameSite: "lax",
      expires: expirationDate,
    });

    const redirectParam = searchParams.get("redirect");
    // Redirect to the top-level domain application running on a different port
    window.location.href = redirectParam ? redirectParam : "https://samnytt.se";
  };

  const signInUser = async () => {
    const error = await signIn(email, password);
    if (
      error?.code === "auth/wrong-password" ||
      error?.code === "auth/invalid-email"
    )
      return setPasswordError(true);

    if (location.pathname === "/sign-in") {
      navigate("/");
    } else if (location.pathname === "/auth") {
      setCookieForRedirect();
    }
  };

  const signUpUser = async (data: UserData) => {
    await signUp(password, data);
    //TODO: Error handle signup (https://firebase.google.com/docs/reference/js/auth#autherrorcodes)
    navigate("/");
  };

  const redirectUser = () => {
    setCookieForRedirect();
  };

  if (loading) return null;

  return (
    <div className="min-h-screen mx-4 flex items-center justify-center">
      <div className="p-4 my-8 sm:p-8 mb-44 w-[28rem] samnytt-card">
        {userData ? (
          <RedirectFlow
            userName={userData.name}
            onAction={redirectUser}
            onSecondaryAction={logOut}
          />
        ) : (
          <>
            {!isUserProvidedEmail && (
              <EmailFlow
                email={email}
                setEmail={setEmail}
                continueWithEmail={continueWithEmail}
              />
            )}

            {isUserProvidedEmail && !isEmailUsed && (
              <SignUpFlow
                email={email}
                password={password}
                passwordRepeated={passwordRepeated}
                setPasswordRepeated={setPasswordRepeated}
                setPassword={setPassword}
                signUpUser={signUpUser}
                backToEmail={() => setIsUserProvidedEmail(false)}
              />
            )}

            {isUserProvidedEmail && isEmailUsed && (
              <SignInFlow
                email={email}
                password={password}
                setPassword={setPassword}
                passwordError={passwordError}
                signInUser={signInUser}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SignIn;
