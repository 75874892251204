import { FunctionComponent } from "react";
import PlanDurationOptions from "../components/PlanDurationOptions";
import { useFirebase } from "../lib/firebaseContext";
interface PlanOptionsProps {}

const PlanOptions: FunctionComponent<PlanOptionsProps> = () => {
  const { createUserCheckout } = useFirebase();

  return <PlanDurationOptions onCheckout={createUserCheckout} />;
};

export default PlanOptions;
