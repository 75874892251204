import { FunctionComponent, useState } from "react";
import TextInput from "./input/TextInput";
import { UserData } from "../types/UserData";
import { useFirebase } from "../lib/firebaseContext";

interface UserDetailsFormProps {
  title: string;
  userData: UserData;
  closeModal: () => void;
}

const UserDetailsForm: FunctionComponent<UserDetailsFormProps> = ({
  title,
  userData,
  closeModal,
}) => {
  const { user, updateUserDocument } = useFirebase();

  const [userFields, setUserFields] = useState<UserData>({
    name: userData.name,
    surname: userData.surname,
    email: userData.email,
    address: userData.address,
    city: userData.city,
    zipcode: userData.zipcode,
    telephone: userData.telephone,
  });

  const saveUserDetails = async () => {
    if (user) {
      await updateUserDocument(user.uid, userFields);
      //TODO add ui loader
      closeModal();
    }
  };

  const userFieldsHandler = (fieldName: string, val: string) => {
    setUserFields({ ...userFields, [fieldName]: val });
  };
  return (
    <>
      <div className="mb-3 flex justify-between items-center">
        <h1 className="mb-0 samnytt-card-headline">{title}</h1>
        <button
          className="m-0 relative samnytt-svg-wrapper-for-btn"
          onClick={closeModal}
        >
          <svg
            className="samnytt-svg-icon fill-gray-600 hover:fill-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 96 960 960"
            width="24"
          >
            <path d="m259 850-52-53 220-221-220-221 52-53 221 221 221-221 52 53-220 221 220 221-52 53-221-221-221 221Z" />
          </svg>
        </button>
      </div>
      <div className="flex">
        <TextInput
          className="mr-6"
          label="Förnamn"
          fieldName="name"
          value={userFields.name}
          onChange={userFieldsHandler}
        />
        <TextInput
          label="Efternamn"
          fieldName="surname"
          value={userFields.surname}
          onChange={userFieldsHandler}
        />
      </div>
      <TextInput
        label="E-postadress"
        placeholder="Gatunamn och nummer"
        fieldName="email"
        value={userFields.email}
        onChange={userFieldsHandler}
      />
      <TextInput
        label="Gatuadress"
        fieldName="address"
        value={userFields.address}
        onChange={userFieldsHandler}
      />
      <div className="flex">
        <TextInput
          className="mr-6"
          label="Ort"
          fieldName="city"
          value={userFields.city}
          onChange={userFieldsHandler}
        />
        <TextInput
          label="Postnummer"
          fieldName="zipcode"
          value={userFields.zipcode}
          onChange={userFieldsHandler}
        />
      </div>
      <TextInput
        className="mb-20"
        label="Telefon"
        fieldName="telephone"
        value={userFields.telephone}
        onChange={userFieldsHandler}
      />
      <button className="mt-3 samnytt-primary-btn" onClick={saveUserDetails}>
        Spara uppdaterade uppgifter
      </button>
    </>
  );
};

export default UserDetailsForm;
