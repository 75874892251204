import React, { ReactElement, useContext, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  RouteProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useFirebase } from "../lib/firebaseContext";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, loading } = useFirebase();
  const location = useLocation();

  useEffect(() => {
    if (!loading && !user) {
      //navigate("/sign-in", { state: { from: location.pathname } });
    }
  }, [user, loading]);

  if (!loading && !user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
