import { Dispatch, FunctionComponent } from "react";
import PasswordInput from "./input/PasswordInput";
import ErrorMessage from "./ErrorMessage";
import useKey from "../hooks/useKey";

interface SignInFlowProps {
  email: string;
  password: string;
  setPassword: Dispatch<React.SetStateAction<string>>;
  signInUser: () => Promise<void>;
  passwordError: boolean;
}

const SignInFlow: FunctionComponent<SignInFlowProps> = ({
  email,
  password,
  setPassword,
  signInUser,
  passwordError,
}) => {
  const submitPassword = () => {
    if (!password) return;
    signInUser();
  };

  useKey("Enter", submitPassword, [password]);

  return (
    <>
      <h1 className="samnytt-card-headline">Välkommen tillbaka, {email}</h1>

      <PasswordInput
        value={password}
        label="Lösenord"
        onChange={setPassword}
        placeholder="Ange ditt lösenord"
        autoFocus
      />
      {passwordError && <ErrorMessage text="Fel lösenord." />}

      <button className="mt-6 samnytt-primary-btn" onClick={submitPassword}>
        Logga in
      </button>
    </>
  );
};

export default SignInFlow;
