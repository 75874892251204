import { FunctionComponent, useState } from "react";

interface PrimaryButtonProps {
  name: string;
  onClick: any; //TODO: Add type
  className?: string;
}

const PrimaryButton: FunctionComponent<PrimaryButtonProps> = ({
  name,
  onClick,
  className = "",
}) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const clickHandler = (): void => {
    onClick();
    setShowSpinner(true);
  };

  return (
    <button
      className={`flex justify-center items-center samnytt-primary-btn ${className}`}
      onClick={clickHandler}
    >
      {!showSpinner ? (
        name
      ) : (
        <div
          className="shrink-0 animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent rounded-full text-[#d9f5f1]"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </button>
  );
};

export default PrimaryButton;
