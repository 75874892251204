import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCS4WBKCwT6WN6_nU0pG4JBpv8ukPCpLj0",

  authDomain: "samnytt-98661.firebaseapp.com",

  projectId: "samnytt-98661",

  storageBucket: "samnytt-98661.appspot.com",

  messagingSenderId: "608468424013",

  appId: "1:608468424013:web:f9641e4285895bdb269304",

  measurementId: "G-VMGK5FKPQ4",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
