import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../lib/firebaseContext";

interface SubscriptionDetailsProps {
  userSubscriptions: any;
  className?: string;
}

const SubscriptionDetails: FunctionComponent<SubscriptionDetailsProps> = ({
  userSubscriptions,
  className = "",
}) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState<
    "active" | "canceled" | "cancelling" | null
  >(null);

  const [productName, setProductName] = useState<string>("...");
  const [price, setPrice] = useState<string>("...");
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<string>("...");
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState<boolean>(false);
  const { createUserPortal } = useFirebase();
  const [loadingPortal, setLoadingPortal] = useState(false);

  const navigate = useNavigate();

  const onChangeSubscription = async () => {
    setLoadingPortal(true);
    await createUserPortal();
    setLoadingPortal(false);
  };

  useEffect(() => {
    const lastSub = userSubscriptions?.[userSubscriptions.length - 1];

    if (!lastSub) return;

    setSubscriptionStatus(lastSub?.status);

    // setProductName
    const name = lastSub?.items[0].price.product.name;
    if (name) setProductName(name);

    // setPrice
    const price = lastSub?.items[0].price.unit_amount / 100;
    setPrice(`${price} kr/månad`);

    // setCurrentPeriodEnd
    const seconds = lastSub?.current_period_end?.seconds;
    if (seconds) {
      const formatDate = (seconds: number): string => {
        const date = new Date(seconds * 1000);
        const options: Intl.DateTimeFormatOptions = {
          month: "long",
          day: "numeric",
          year: "numeric",
        };
        const formattedDate = date.toLocaleDateString("sv-SE", options);
        return formattedDate;
      };
      setCurrentPeriodEnd(formatDate(seconds));
    }

    // setCancelAtPeriodEnd
    const cancelAtPeriodEnd = lastSub?.cancel_at_period_end;
    setCancelAtPeriodEnd(cancelAtPeriodEnd);
    if (cancelAtPeriodEnd) setSubscriptionStatus("cancelling");
  }, [userSubscriptions]);

  if (!subscriptionStatus)
    return (
      <div className={`samnytt-card ${className}`}>
        <p className="samnytt-card-headline">Din prenumeration</p>
        <p className="mb-4 text-base font-medium">
          Du har ingen aktiv prenumeration just nu.
        </p>

        <button
          className="h-11 w-full py-3 px-4 rounded font-semibold text-black bg-[#ffde53] hover:bg-[#fed945] active:bg-[#fad33a] transition-all text-sm select-none outline-none"
          onClick={() => navigate("/checkout")}
        >
          Påbörja prenumeration
        </button>
      </div>
    );

  return (
    <div className={`samnytt-card ${className}`}>
      <p className="samnytt-card-headline">Din prenumeration</p>

      {subscriptionStatus === "active" && !cancelAtPeriodEnd && (
        <div className=" bg-[#ffde53] mb-4 w-fit text-black text-sm font-semibold py-2 px-3 rounded-md">
          Aktiv prenumeration
        </div>
      )}
      {subscriptionStatus === "cancelling" && (
        <div className=" bg-gray-200 mb-4 w-fit text-gray-500 text-sm font-semibold py-2 px-3 rounded-md">
          Väntar på avslutas
        </div>
      )}
      {subscriptionStatus === "canceled" && (
        <div className=" bg-gray-200 mb-4 w-fit text-gray-500 text-sm font-semibold py-2 px-3 rounded-md">
          Avslutad prenumeration
        </div>
      )}
      <p className="mb-1 text-base text-gray-600">Nivå</p>
      <p className="mb-3 text-base font-semibold">{productName}</p>
      <p className="mb-1 text-base text-gray-600 ">Kostnad</p>
      <p className="mb-3 text-base font-semibold">{price}</p>

      <p className="mb-1 text-base text-gray-600">
        {cancelAtPeriodEnd ? "Avslutas" : "Nästa förnyelse"}
      </p>
      <p className="mb-3 text-base font-semibold">{currentPeriodEnd}</p>

      <button className="samnytt-primary-btn" onClick={onChangeSubscription}>
        {loadingPortal ? "Laddar..." : "Ändra prenumeration"}
      </button>
      <p className="mt-3.5 text-sm text-gray-400 text-center">
        Här kan du ändra eller avsluta din prenumeration.
      </p>
      {/*  <button
            className="mt-3 samnytt-secondary-btn"
            onClick={createUserPortal}
          >
            Avbryt prenumeration
          </button> */}
    </div>
  );
};

export default SubscriptionDetails;
