import { Dispatch, FunctionComponent } from "react";

interface TextInputProps {
  label: string;
  fieldName: string;
  value: string;
  onChange: Dispatch<React.SetStateAction<string>> | any;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const TextInput: FunctionComponent<TextInputProps> = ({
  label,
  fieldName,
  value,
  onChange,
  className = "",
  placeholder = "",
  autoFocus = false,
  disabled = false,
}) => {
  return (
    <p className={`w-full shrink-1 mb-4 ${className}`}>
      <label className="samnytt-input-label" htmlFor={fieldName}>
        {label}:
      </label>
      <input
        onChange={(e) => onChange(fieldName, e.target.value)}
        className="samnytt-input"
        placeholder={placeholder}
        value={value}
        type="text"
        id={fieldName}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </p>
  );
};

export default TextInput;
