import { FunctionComponent, useEffect, useState } from "react";
import BillingHistoryItem from "./BillingHistoryItem";
import { useFirebase } from "../lib/firebaseContext";

interface BillingHistoryProps {
  className?: string;
}

const BillingHistory: FunctionComponent<BillingHistoryProps> = ({
  className = "",
}) => {
  const { userInvoices } = useFirebase();

  return (
    <div className={`samnytt-card ${className}`}>
      <p className="m-0 mb-3 shrink-0 samnytt-card-headline">Billing history</p>

      {userInvoices?.length ? (
        userInvoices.map((invoice: any) => {
          return <BillingHistoryItem invoice={invoice} key={invoice.created} />;
        })
      ) : (
        <p>No subscriptions yet.</p>
      )}
    </div>
  );
};

export default BillingHistory;
