import { FunctionComponent } from "react";

interface RedirectFlowProps {
  userName: string;
  onAction: () => void;
  onSecondaryAction: () => void;
}

const RedirectFlow: FunctionComponent<RedirectFlowProps> = ({
  userName,
  onAction,
  onSecondaryAction,
}) => {
  return (
    <>
      <h1 className="mb-5 samnytt-card-headline">Hej, {userName}</h1>
      <button className="mb-3 samnytt-primary-btn" onClick={onAction}>
        Fortsätt
      </button>
      <button className="samnytt-secondary-btn" onClick={onSecondaryAction}>
        Inte du?
      </button>
    </>
  );
};

export default RedirectFlow;
