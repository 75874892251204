import { FunctionComponent, useEffect } from "react";
import { useFirebase } from "../lib/firebaseContext";
import SubscriptionDetails from "../components/SubscriptionDetails";
import UserDetails from "../components/UserDetails";
import ContactSupport from "../components/ContactSupport";
import BillingHistory from "../components/BillingHistory";

interface DashboardProps {}

const Dashboard: FunctionComponent<DashboardProps> = () => {
  const { userData, loading, userSubscriptions } = useFirebase();

  if (loading)
    return (
      <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
        <div
          className="animate-spin inline-block w-20 h-20 border-[9px] border-current border-t-transparent rounded-full text-[#21a591]"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="sm:flex w-full">
      <div className="m-0 sm:pr-2.5 w-full sm:w-1/2 mx-auto">
        <UserDetails
          className="m-0 mb-5 max-w-full w-full"
          userData={userData}
        />
        <ContactSupport className="m-0 mb-5 max-w-full w-full" />
      </div>
      <div className="m-0 sm:pl-2.5 w-full sm:w-1/2 mx-auto">
        <SubscriptionDetails
          className="m-0 mb-5 max-w-full w-full"
          userSubscriptions={userSubscriptions}
        />
        <BillingHistory
          className="m-0 mb-5 max-w-full w-full"
          // userSubscriptions={userSubscriptions}
        />
      </div>
    </div>
  );
};

export default Dashboard;
