import { Route, Routes } from "react-router-dom";
import Dashboard from "../views/Dashboard";
import SignIn from "../views/SignIn";
import PrivateRoute from "./PrivateRoute";
import AppLayout from "../layouts/AppLayout";
import Checkout from "../views/Checkout";
import PlanOptions from "../views/PlanOptions";

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />
        <Route
          path="/plan-options"
          element={
            <PrivateRoute>
              <PlanOptions />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/auth" element={<SignIn />} />
    </Routes>
  );
};
