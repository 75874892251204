import { useEffect } from "react";

const useKey = (
  targetKey: string,
  callback: () => void,
  dependencies?: any
) => {
  const handler = ({ key }: KeyboardEvent) => {
    if (targetKey === key) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [dependencies]);

  return;
};

export default useKey;
