import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";

interface AppLayoutProps {}

const AppLayout: FunctionComponent<AppLayoutProps> = () => {
  return (
    <>
      <Header />
      <div className="sm:flex sm:items-start sm:flex-wrap min-h-fit justify-center max-w-5xl p-4 mx-auto">
        <Outlet />
      </div>
    </>
  );
};

export default AppLayout;
