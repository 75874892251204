import { FunctionComponent, useState } from "react";
import Modal from "./Modal";
import UserDetailsForm from "./UserDetailsForm";
import { UserData } from "../types/UserData";

const DataItem = (props: any) => {
  return (
    <div
      className={`w-full flex flex-col justify-between mb-2.5 ${props.className}`}
    >
      <p className="text-gray-700">{props.name}</p>
      <p className="font-medium">{props.value}</p>
    </div>
  );
};
interface UserDetailsProps {
  userData: UserData;
  className?: string;
}

const UserDetails: FunctionComponent<UserDetailsProps> = ({
  userData,
  className = "",
}) => {
  let [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={`samnytt-card ${className}`}>
      <div className="mb-5 flex justify-between items-center">
        <p className="m-0 shrink-0 samnytt-card-headline">Kontoinformation</p>

        <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <UserDetailsForm
            closeModal={() => setIsModalOpen(false)}
            userData={userData}
            title="Uppdatera kontoinformation"
          />
        </Modal>
      </div>

      {!userData ? (
        <div>Laddar...</div>
      ) : (
        <>
          <DataItem name="E-postadress" value={userData.email} />
          <div className="flex sm:block">
            <DataItem name="Förnamn" value={userData.name} />
            <DataItem name="Efternamn" value={userData.surname} />
          </div>
          <div className="flex sm:block">
            <DataItem name="Gatuadress" value={userData.address} />
            <DataItem name="Ort" value={userData.city} />
          </div>
          <div className="flex sm:block">
            <DataItem name="Postnummer" value={userData.zipcode} />
            <DataItem name="Telefon" value={userData.telephone} />
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;
