import { FunctionComponent } from "react";
import PrimeryButton from "./PrimaryButton";

interface PlanCardProps {
  name: string;
  price: string;
  benefits: string[];
  // Add type
  onCheckout: any;
  className?: string;
  classNameActionBtn?: string;
}

const PlanCard: FunctionComponent<PlanCardProps> = ({
  name,
  price,
  benefits,
  onCheckout,
  className = "",
  classNameActionBtn = "",
}) => {
  return (
    <div className={`samnytt-card ${className}`}>
      <p className="mb-1 text-xl font-bold text-center">{name}</p>
      <p className="mb-4 text-md font-bold text-center">{price}</p>
      <ul className="mb-6">
        {benefits.map((item, index) => (
          <li key={index} className="flex text-gray-700 leading-[1.75]">
            <svg
              className="shrink-0 mr-1.5 fill-[#64bfb2]"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 96 960 960"
              width="24"
            >
              <path d="M382 848 122 588l90-90 170 170 366-366 90 90-456 456Z" />
            </svg>

            {item}
          </li>
        ))}
      </ul>

      <PrimeryButton
        className={`mb-6 ${classNameActionBtn}`}
        name="Gå vidare"
        onClick={onCheckout}
      />

      <p className="text-center text-gray-700">
        Ingen bindningstid - Avsluta prenumerationen online när som helst
      </p>
    </div>
  );
};

export default PlanCard;
