import { format } from "path";
import { FunctionComponent, useEffect, useState } from "react";

interface BillingHistoryItemProps {
  invoice: any;
}

const BillingHistoryItem: FunctionComponent<BillingHistoryItemProps> = ({
  invoice,
}) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [createdDate, setCreatedDate] = useState<string>("...");
  const [price, setPrice] = useState<string>("...");

  useEffect(() => {
    const seconds = invoice.created;
    if (seconds) {
      const formatDate = (seconds: number): string => {
        const date = new Date(seconds * 1000);
        const options: Intl.DateTimeFormatOptions = {
          month: "long",
          day: "numeric",
          year: "numeric",
        };
        const formattedDate = date.toLocaleDateString("sv-SE", options);
        return formattedDate;
      };
      setCreatedDate(formatDate(seconds));
    }

    const price = invoice.amount_paid;
    const formatPrice = (num: number): string => {
      const val = `${(price / 100).toString()} kr`;
      return val;
    };
    setPrice(formatPrice(price));
  }, [invoice]);

  const downloadInvoicePdf = () => {
    setShowSpinner(true);
    window.location.href = invoice.invoice_pdf;
    setInterval(() => {
      setShowSpinner(false);
    }, 3000);
  };

  return (
    <>
      <div
        className="flex items-center justify-between h-14 w-full mb-2.5 pl-3 pr-1.5 text-gray-600 border bg-gray-50
         border-gray-150 rounded-md hover:text-gray-600 "
      >
        <div className="min-w-0 w-full font-medium text-md text-gray-700">
          {createdDate}
        </div>
        <div className="w-2/4 font-medium text-md text-gray-700">{price}</div>
        <button
          onClick={downloadInvoicePdf}
          className="shrink-0 flex justify-center items-center h-10 w-10 rounded bg-transparent
        hover:bg-gray-200 transition [&>*:first-child]:hover:fill-gray-600"
        >
          {showSpinner ? (
            <div
              className="shrink-0 animate-spin inline-block w-5 h-5 border-[3px] border-current border-t-transparent rounded-full text-gray-400"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <svg
              className="fill-gray-500 transition"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 96 960 960"
              width="24"
            >
              <path d="M240 896q-33 0-56.5-23.5T160 816V696h80v120h480V696h80v120q0 33-23.5 56.5T720 896H240Zm240-160L280 536l56-58 104 104V256h80v326l104-104 56 58-200 200Z" />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};

export default BillingHistoryItem;
