import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface HeaderBackButtonProps {}

const HeaderBackButton: FunctionComponent<HeaderBackButtonProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  }, [location]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <button
      className={`${
        isShown ? "" : "invisible"
      } flex justify-center items-center shrink-0 bg-gray-100 text-white text-xl font-semibold h-10
   w-10 mr-4 rounded-full  active:bg-gray-200 hover:bg-gray-200 transition-all cursor-pointer`}
      onClick={goBack}
    >
      <svg
        className="fill-gray-500 hover:fill-gray-600"
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 96 960 960"
        width="24"
      >
        <path d="M480 896 160 576l320-320 57 56-224 224h487v80H313l224 224-57 56Z" />
      </svg>
    </button>
  );
};

export default HeaderBackButton;
