import { FunctionComponent } from "react";
import { PlanOptions } from "../types/PlanOptions";

interface RadioOptionProps {
  id: string;
  discription: string;
  onClick: any;
  active: PlanOptions;
  className?: string;
}
const RadioOption: FunctionComponent<RadioOptionProps> = ({
  id,
  discription,
  onClick,
  active,
  className = "",
}) => {
  return (
    <div className={` ${className}`}>
      <input
        type="radio"
        id={id}
        name="plan-options"
        value={id}
        className="hidden peer"
        required
        onChange={() => onClick(id)}
        checked={active === id ? true : false}
      />
      <label
        htmlFor={id}
        className="inline-flex items-center justify-between h-14 w-full pl-5 pr-1 text-gray-600 border bg-gray-50
         border-gray-100 rounded-md cursor-pointer  peer-checked:border-gray-900 peer-checked:bg-[#ffdc5b] 
         peer-checked:text-black hover:text-gray-600 hover:bg-gray-50"
      >
        {discription}
      </label>
    </div>
  );
};
export default RadioOption;
